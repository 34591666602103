import { Link } from 'gatsby'
import React from 'react'

const invertedAccLogo =
  '//a.storyblok.com/f/64835/x/3ad82df92d/acc-single-line-white-2022.svg'

export const LogoLink = ({ handHeldDevice, invertedNav, logoImage }) =>
  logoImage ? (
    <Link
      id="top-nav-logo-container"
      to="/"
      style={{
        display: 'flex',
        flex: 1,
        maxWidth: `${handHeldDevice ? '210px' : '390px'}`,
      }}
    >
      <img
        src={invertedNav ? invertedAccLogo : logoImage}
        alt="Autodesk Construction Cloud Logo"
        style={{
          width: '100%',
        }}
      />
    </Link>
  ) : null
