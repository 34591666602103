import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ACC_THEME from '../../themes'
import ContentContainer from './content-container'
import H3 from './h3'
import Img from './img'
import font from '@helpers/font'
import Icon from '@system/icon'
const { makeResponsiveFontSizes } = font

const useStyles = makeStyles({
  title: ({ largeTitlePadding, modalIsDark }) => ({
    textAlign: 'center',
    backgroundColor:
      ACC_THEME.palette.background[modalIsDark ? 'dark' : 'white'],
    color: ACC_THEME.palette.text[modalIsDark ? 'white' : 'primary'],
    padding: largeTitlePadding ? '3rem 10rem' : '16px 24px 0',
    display: 'flex',
    justifyContent: 'center',
    [ACC_THEME.breakpoints.down('sm')]: {
      padding: '1rem 2rem',
    },
  }),
  dialogTitle: ({ modalIsDark }) => ({
    fontWeight:
      ACC_THEME.typography[
        modalIsDark ? 'fontWeightExtraBold' : 'fontWeightRegular'
      ],
    color: ACC_THEME.palette.text[modalIsDark ? 'white' : 'primary'],
    width: modalIsDark ? '70%' : '100%',
    marginBottom: '0',
    ...makeResponsiveFontSizes(50, 15),
  }),
  content: ({ modalIsDark, title }) => ({
    backgroundColor:
      ACC_THEME.palette.background[modalIsDark ? 'dark' : 'white'],
    padding: title ? '1rem 3rem 2rem' : '2rem 3rem',
    '& button': {
      margin: '0 auto',
    },
    [ACC_THEME.breakpoints.down('xs')]: {
      padding: '1rem',
    },
  }),
  logo: {
    margin: '16px 0px',
    '& img': {
      width: '225px !important',
      objectFit: 'contain',
    },
  },
  meetupDate: {
    fontSize: '25px',
    color: ACC_THEME.palette.primary.main,
    fontWeight: ACC_THEME.typography.fontWeightMedium,
  },
  meetupTitle: {
    fontSize: '25px',
  },
  closeButton: {
    appearance: 'none',
    background: 'none',
    border: 'none',
    color: ACC_THEME.palette.text.tertiary,
    cursor: 'pointer',
    padding: '0',
    position: 'absolute',
    right: '16px',
    top: '16px',
    width: '24px',
    zIndex: 1,
  },
  closeButtonIcon: {
    display: 'block',
  },
})

const Modal = (props) => {
  const {
    title,
    handleClose,
    open,
    children,
    smallerWidth,
    meetupDetails,
    customSize,
    className,
  } = props

  const classes = useStyles(props)

  const modalWidth =
    customSize === 'xs'
      ? 'xs'
      : customSize === 'sm'
      ? 'sm'
      : customSize === 'md'
      ? 'md'
      : customSize === 'lg'
      ? 'lg'
      : customSize === 'xl'
      ? 'xl'
      : customSize === 'false'
      ? 'false'
      : smallerWidth
      ? 'xs'
      : meetupDetails
      ? 'sm'
      : 'md'

  return (
    <ContentContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        maxWidth={modalWidth}
        className={className}
      >
        <button onClick={handleClose} className={classes.closeButton}>
          <Icon styles={classes.closeButtonIcon}>CloseIcon</Icon>
        </button>
        {title && (
          <DialogTitle
            id="form-dialog-title"
            className={classes.title}
            disableTypography={true}
          >
            <H3 className={classes.dialogTitle}>{title}</H3>
          </DialogTitle>
        )}
        <DialogContent className={classes.content}>
          {meetupDetails && (
            <Box>
              <Box className={classes.logo}>
                <Img src={meetupDetails.meetupIcon} />
              </Box>
              <H3 className={classes.meetupDate}>{meetupDetails.meetupDate}</H3>
              <H3 className={classes.meetupTitle}>
                {meetupDetails.meetupTitle}
              </H3>
            </Box>
          )}
          {children}
        </DialogContent>
      </Dialog>
    </ContentContainer>
  )
}

export default Modal
