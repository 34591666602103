import isInBrowser from '@helpers/is-in-browser'
import { useState, useEffect } from 'react'

const useParams = (initialParams = '', url = '', forwardUTMParams = false) => {
  const [params, setParams] = useState(new URLSearchParams(initialParams))
  const existingURLParams = url.split('?')[1]
  const queryPrefix = existingURLParams ? '&' : '?'

  useEffect(() => {
    if (isInBrowser) {
      let tempParams = new URLSearchParams(params.toString())
      let urlParams = new URLSearchParams(window.location.search)
      const referrer = document.referrer || 'direct'
      const isACCLink = url && url.indexOf('//acc.autodesk.com') > -1

      const replacements = {
        __lt_: '',
        referrer: 'marketing_referrer',
      }

      for (let [key, value] of urlParams.entries()) {
        key.startsWith('_storyblok') && !!value && tempParams.set(key, value)
      }

      const buildUTMParams = () => {
        tempParams.set('referrer_url', document.location.href)
        tempParams.set('marketing_referrer', referrer)
        document.cookie
          .split('; ')
          .filter((item) => item.startsWith('__lt_'))
          .forEach((item) => {
            item = item.split('=')
            item[1] &&
              tempParams.set(
                item[0].replace(
                  /__lt_|referrer/g,
                  (match) => replacements[match]
                ),
                item[1]
              )
          })

        for (let [key, value] of urlParams.entries()) {
          key.startsWith('utm_') && !!value && tempParams.set(key, value)
        }
      }

      if (forwardUTMParams || isACCLink) buildUTMParams()

      setParams(tempParams)
    }
  }, [])
  return params.toString() ? `${queryPrefix}${params.toString()}` : ''
}

export default useParams
