import isInBrowser from '@helpers/is-in-browser'
import {
  localizedSiteData,
  userGeoSiteMatchData,
  primaryBuildFolder,
} from '@variables/global-variables'

// Matches user geo locale to one of our builds OR returns 'us' if no match found
// Allows grouping of ISO codes(As with EU countries), so that any locale in that group can return the desired match for those locales
const getUserSiteLocale = (locale) => {
  return (
    Object.keys(userGeoSiteMatchData).find(
      (key) => userGeoSiteMatchData[key].indexOf(locale) > -1
    ) || 'us'
  )
}

// Return more localized url for user locale or false
const getGeoBannerUrl = (locale) => {
  if (isInBrowser && locale && locale !== 'default') {
    const optimalSiteLocale = getUserSiteLocale(locale)
    const location = window.location.origin
    const path = window.location.pathname

    // filter geoAlternates for locale matching getUserLocale and check that it matches current browser location
    // If in a deploy preview, match current site build to geoMatched optimal site build value
    const isDeployPreview = location.match(/netlify.app|localhost:/)
    const geoMatchedSite = Object.values(localizedSiteData).find(
      (val) => val.locale === optimalSiteLocale
    )
    const geoMatchedUrl = geoMatchedSite?.url

    const isMatched = isDeployPreview
      ? geoMatchedSite?.buildFolders.slice(-1).pop() === primaryBuildFolder
      : geoMatchedUrl === location

    return isMatched ? false : `${geoMatchedUrl}${path}`
  }
  return false
}

export { getGeoBannerUrl }
