import React, { useState } from 'react'
import { TextField, TextareaAutosize } from '@material-ui/core'
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  textField: {
    '& input': {
      height: '44px',
      padding: '0px 14px',
      fontSize: '20px',
      borderRadius: '0',
      backgroundColor: theme.palette.common.white,
      '&::placeholder': {
        color: theme.palette.text.placeholder,
        opacity: 1,
      },
    },
    '& fieldset': {
      borderRadius: '0',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
  },
  textFieldError: {
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
      '&:hover': {
        borderColor: 'red',
      },
    },
  },
  textArea: {
    height: '44px',
    padding: '9px 14px',
    fontSize: '20px',
    font: 'inherit',
    borderColor: theme.palette.action.selected,
    borderRadius: '0',
    marginTop: '16px',
    width: '100%',
    '&::placeholder': {
      color: theme.palette.text.placeholder,
      opacity: 1,
    },
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      outline: 'none',
    },
    '&:focus': {
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      },
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  textAreaError: {
    borderColor: 'red',
    '&:hover': {
      borderColor: 'red',
    },
    '&:focus': {
      borderColor: 'red',
      '&:hover': {
        borderColor: 'red',
      },
    },
  },
  select: {
    width: '100%',
    padding: '8px 0px 6px',
    '& label': {
      color: theme.palette.background.slate,
      fontSize: '20px',
      transform: 'translate(13px,20px)',
    },
    '& svg': {
      fontSize: '1.5rem',
      color: theme.palette.background.slate,
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiFormLabel-filled': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
  },
  selectInput: {
    height: '44px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0',
    fontSize: '20px',
    '& > div': {
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  inputLabel: {
    zIndex: '1',
    color: `${theme.palette.text.placeholder} !important`,
  },
}))

const selectInputType = (props, classes) => {
  const {
    type,
    defaultValue,
    fullWidth,
    helperText,
    margin,
    name,
    placeholder,
    required,
    rows,
    rowsMax,
    className,
    index,
    options,
  } = props

  const [selectValue, setSelectValue] = useState('')
  const [open, setOpen] = useState(false)

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value)
  }

  const toggleSelectStatus = (status) => {
    setOpen(status)
  }

  switch (type) {
    case 'text':
      return (
        <TextField
          type={type || 'text'}
          fullWidth={fullWidth}
          helperText={helperText}
          margin={margin || 'normal'}
          defaultValue={defaultValue}
          name={name}
          required={required}
          placeholder={placeholder}
          className={`${className || classes.textField} ${
            props.error && classes.textFieldError
          }`}
          variant="outlined"
          {...props}
        />
      )

    case 'textarea':
      return (
        <TextareaAutosize
          rows={rows || 3}
          rowsMax={rowsMax}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={`${classes.textField} ${className || classes.textArea} ${
            props.error && classes.textAreaError
          }`}
          required={required}
          name={props.name}
          onBlur={props.onBlur}
          onChange={props.onChange}
          value={props.value}
        >
          {props.children}
        </TextareaAutosize>
      )
    case 'select':
      return (
        <FormControl className={classes.select}>
          {!props.value && (
            <InputLabel className={classes.inputLabel} htmlFor={name}>
              {placeholder}
            </InputLabel>
          )}
          <Select
            className={classes.selectInput}
            variant="outlined"
            name={name}
            value={selectValue}
            onChange={handleSelectChange}
            open={open}
            onClose={() => toggleSelectStatus(false)}
            onOpen={() => toggleSelectStatus(true)}
            {...props}
          >
            {!!options &&
              options.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      )
    default:
      return ''
  }
}

const Input = (props) => {
  const classes = useStyles()
  return selectInputType(props, classes)
}

export default Input
