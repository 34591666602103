import loadable from '@loadable/component'
import H1 from './h1'
import H2 from './h2'
import H3 from './h3'
import H4 from './h4'
import H5 from './h5'
import H6 from './h6'
import HR from './hr'
import Caption from './caption'
import RadioGroup from './radio-group'
import EventTimer from './event-timer'
import Text from './text'
import TextSm from './text-sm'
import BackgroundImage from './background-image'
import Button from './button'
import InputField from './form-input'
import Img from './img'
import SystemSkeleton from './skeleton'
import MarkdownHandler from './markdown'
import WistiaVideoOverlay from './wistia-modal'
import HangingQuote from './hanging-quote'
import Blockquote from './blockquote'
import PageContainer from './page-container'
import ContentContainer from './content-container'
import VideoScreenshotsPreview from './video-screenshots-preview'
import EventsCard from './events-card'
import Link from './link'
import AutodeskProductLogo from './autodesk-product-logo'
import Modal from './popup-modal'
import BlogCard from './blog-card'
import CustomerCards from './customer-cards'
import ResourceCard from './resource-card'
import Icon from './icon'
import Phases from './phases'
import Tooltip from './tooltip'
import Accordion from './accordion'
import Mark from './mark'
import ProfileCard from './profile-card'
const Video = loadable(() => import('./video'))

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  HR,
  Caption,
  RadioGroup,
  EventTimer,
  Text,
  TextSm,
  BackgroundImage,
  Button,
  InputField,
  Img,
  SystemSkeleton,
  MarkdownHandler,
  WistiaVideoOverlay,
  HangingQuote,
  Blockquote,
  PageContainer,
  ContentContainer,
  VideoScreenshotsPreview,
  EventsCard,
  Link,
  AutodeskProductLogo,
  Modal,
  BlogCard,
  CustomerCards,
  ResourceCard,
  Icon,
  Phases,
  Tooltip,
  Accordion,
  Mark,
  ProfileCard,
  Video,
}
