import React, { useContext } from 'react'
import { Box, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import get from 'lodash/get'
import PageContext from '@context'
import { Img, TextSm } from '@system'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  image: {
    height: '96px',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      height: '144px',
    },
    '&>div': {
      height: 'inherit',
    },
    '& img': {
      objectFit: 'cover',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 0 auto',
  },
  companyName: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    display: 'block',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '8px',
    },
  },
  projectName: {
    color: theme.palette.text.secondary,
    display: 'block',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '8px',
    },
  },
  logos: {
    paddingRight: '8px',
    '& img': {
      height: '24px',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '24px',
      '& img': {
        height: '32px',
      },
    },
  },
  link: {
    textDecoration: 'none',
  },
}))

const CustomerCards = (props) => {
  const { title, image, companyName, projectName, productsUsed } = props.blok
  const pageContext = useContext(PageContext)
  const classes = useStyles()

  const getCustomerStorySlug = (title) => {
    const filteredStories =
      pageContext.customerStories &&
      pageContext.customerStories.filter(
        (customerStory) => title === customerStory.content.title
      )

    return get(filteredStories, '[0].full_slug')
  }

  const logos = [
    {
      label: 'Assemble',
      imgPath: '//a.storyblok.com/f/64835/x/2b5d477d2e/assemble-icon.svg',
    },
    {
      label: 'BIM360',
      imgPath: '//a.storyblok.com/f/64835/x/73c8fdc944/bim360-icon.svg',
    },
    {
      label: 'BuildingConnected',
      imgPath:
        '//a.storyblok.com/f/64835/x/93e65bc3c0/buildingconnected-icon.svg',
    },
    {
      label: 'PlanGrid',
      imgPath: '//a.storyblok.com/f/64835/x/3e43912148/plangrid-icon.svg',
    },
  ]
  return (
    <Link href={`/${getCustomerStorySlug(title)}`} className={classes.link}>
      <Box className={classes.card}>
        <Box className={classes.image}>
          {!!image && image.length > 0 && (
            <Img maxWidth={300} src={get(image, '[0].image')} />
          )}
        </Box>
        <Box p={1} className={classes.content}>
          {companyName && (
            <TextSm className={classes.companyName}>{companyName}</TextSm>
          )}
          {projectName && (
            <TextSm className={classes.projectName}>{projectName}</TextSm>
          )}
          <Box display="flex" pt={2}>
            {!!logos &&
              logos.map((logo) => {
                return (
                  !!productsUsed &&
                  !!productsUsed.length > 0 &&
                  productsUsed.indexOf(logo.label) !== -1 && (
                    <Box key={logo._uid} className={classes.logos}>
                      <img
                        src={logo.imgPath}
                        alt="Autodesk Construction Cloud product logo"
                      ></img>
                    </Box>
                  )
                )
              })}
          </Box>
        </Box>
      </Box>
    </Link>
  )
}

export default CustomerCards
