import React, { useContext, useState, useEffect } from 'react'
import PageContext from '@context'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import { Link as GatsbyLink } from 'gatsby'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { Box } from '@material-ui/core'
import { Modal } from '@system'
import get from 'lodash/get'
import { addPrecedingSlash, addTrailingSlash } from '@helpers'
import useParams from '@hooks/use-params'
import Icon from '@system/icon'
import useLocalizedStrings from '@hooks/use-localized-strings'

const useStyles = makeStyles((theme) => {
  const isDark = theme.palette.type === 'dark'
  return {
    standard: (props) => ({
      display: 'inline',
      color: isDark || props.isDark ? '#FFFFFF' : '#444',
      borderBottom:
        !props.blok.linkWithoutUnderline &&
        props.blok.textToHyperlink &&
        `1px dashed ${isDark || props.isDark ? '#FFFFFF' : '#444'}`,
      textDecoration: 'none',
      textTransform: props.blok.allCaps ? 'uppercase' : 'none',
      '&:hover': {
        cursor: 'pointer',
        borderBottom:
          !props.blok.linkWithoutUnderline &&
          props.blok.textToHyperlink &&
          `1px solid ${isDark || props.isDark ? '#FFFFFF' : '#444'}`,
      },
    }),
    simpleBold: (props) => ({
      display: 'inline',
      color: isDark ? '#FFFFFF' : '#444',
      textDecoration: 'none',
      textTransform: props.blok.allCaps ? 'uppercase' : 'none',
      '&:hover': {
        color: isDark
          ? theme.palette.background.paper
          : theme.palette.text.secondary,
        cursor: 'pointer',
      },
      fontSize: 12,
      fontWeight: theme.typography.fontWeightBold,
    }),
    bottomNavHeading: (props) => ({
      display: 'block',
      color: theme.palette.common.white,
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        color: isDark
          ? theme.palette.background.paper
          : theme.palette.text.secondary,
        cursor: 'pointer',
      },
    }),
    bottomNavSubmenuLinks: (props) => ({
      display: 'block',
      color: theme.palette.common.white,
      textDecoration: 'none',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        color: isDark
          ? theme.palette.background.paper
          : theme.palette.text.secondary,
        cursor: 'pointer',
      },
    }),
    bottomNavBottomLinks: {
      color: 'inherit',
      fontSize: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        color: isDark
          ? theme.palette.background.paper
          : theme.palette.text.secondary,
        cursor: 'pointer',
      },
    },
    visionMenuHeading: {
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
      paddingBottom: '10px',
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    subLink: {
      color: '#999999',
      textDecoration: 'none',
      fontSize: 11,
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    bannerLink: {
      color: theme.palette.common.white,
      fontWeight: 700,
      fontSize: 16,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline dashed',
      },
    },
    blueWithUnderline: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      letterSpacing: 0,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    blueWithDashed: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      letterSpacing: 0,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
      borderBottom: `1px dashed ${theme.palette.primary.main}`,
      '&:hover': {
        cursor: 'pointer',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
    ressourceCenter: (props) => ({
      color: theme.palette.text.tertiary,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'none',
      textDecoration: 'none',
      borderBottom:
        !props.blok.linkWithoutUnderline &&
        `1px solid ${theme.palette.text.tertiary}`,
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    icon: {
      display: 'inline',
      marginRight: '10px',
      position: 'relative',
      top: '2px',
      '& img': {
        width: '14px !important',
        height: '16px',
      },
    },
    iconSvg: {
      marginRight: '10px',
    },
    constructionWorkflow: (props) => ({
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'none',
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    subMenuBlueThin: (props) => ({
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightNormal,
      textTransform: 'none',
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
        inlineSize: 'fit-content',
      },
      [theme.breakpoints.down('sm')]: {
        color: props.isNav
          ? theme.palette.text.white
          : theme.palette.primary.main,
      },
    }),
    topNavSidebarLinks: () => ({
      display: 'block',
      color: theme.palette.text.grey3,
      textDecoration: 'none',
      textTransform: 'none',
      lineHeight: '19px',
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        color: theme.palette.text.grey3,
        cursor: 'pointer',
        textDecoration: 'underline',
        inlineSize: 'fit-content',
        [theme.breakpoints.down('sm')]: {
          color: theme.palette.text.white,
        },
      },
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.text.white,
        fontSize: 13,
      },
    }),
    deviceFrameCta: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        cursor: 'pointer',
      },
    },

    logInButton: (props) => ({
      display: 'block',
      color: theme.palette.common.white,
      textDecoration: 'none',
      lineHeight: '16px',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    meetupLinkCard: {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      fontSize: 20,
      textDecoration: 'none',
      wordSpacing: '100vw',
    },
    meetupLinkLongCard: {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      fontSize: 20,
      textDecoration: 'none',
    },
    cursorStyle: {
      cursor: 'pointer',
    },
    breadcrumbLink: {
      color: theme.palette.common.white,
      fontSize: 18,
      textDecoration: 'none',
      display: 'Flex',
      flexDirection: 'row',
      alignItems: 'self-end',
    },
  }
})

const Link = (props) => {
  const { blok, style, className, state, replace, target, onClick } = props
  const {
    link: { url, linktype, story },
    textToHyperlink,
    contentToHyperlink,
    noFollow,
    openInNewTab,
    downloadable,
    download,
    variant,
    isLink,
    allCaps,
    params,
    fragment,
    anchorLinkIsInvisible,
    icon,
    modal,
    subLink,
    dataAnalytics,
    dataProduct,
    forcePageLoad,
    iconSvg,
    mutinyConversion,
    ...other
  } = blok

  const context = useContext(PageContext)
  const pageDataProduct =
    !!context.children[1].props.blok &&
    context.children[1].props.blok.dataProduct
  const analytics = {}
  if (dataAnalytics) analytics['data-analytics'] = dataAnalytics
  if (dataProduct) {
    analytics['data-product'] = dataProduct
  } else if (pageDataProduct) {
    analytics['data-product'] = pageDataProduct
  } else analytics['data-product'] = 'generic'

  const popupModal = get(modal, '[0]', {})
  const { title, ...otherModalProps } = popupModal
  const localizedStrings = useLocalizedStrings()
  const classes = useStyles(props)

  const isStory = linktype === 'story'
  const sb_url = (isStory && story && story.url && story.url.trim()) || ''
  const storyName = isStory && story && story.name
  const existingSearch = !!url && url.indexOf('?') > 0

  const [OpenModal, toggleModal] = useState(false)

  // textToHyperlink can be set to non-string value by Markdown component
  const toHyperlink =
    (!!contentToHyperlink &&
      contentToHyperlink.length > 0 &&
      contentToHyperlink.map((content) =>
        renderBlok(content, { isTopNavBanner: !!props.isTopNavBanner })
      )) ||
    textToHyperlink ||
    localizedStrings[
      !!textToHyperlink &&
        typeof textToHyperlink === 'string' &&
        textToHyperlink.toLowerCase()
    ] ||
    storyName ||
    localizedStrings['link'] ||
    'Link'
  const query = useParams(params, url)

  const hash = fragment ? (fragment[0] === '#' ? fragment : `#${fragment}`) : ''
  const storyUrl = isStory ? sb_url.slice(3) : ''
  const isJustAnchorLink = isStory && !storyUrl && !!hash && hash.length > 0
  const initHref = isJustAnchorLink
    ? hash
    : isStory
    ? context.isInEditor
      ? `/editor/?path=${addTrailingSlash(sb_url)}${query.replace(
          '?',
          '&'
        )}${hash}`
      : addPrecedingSlash(`${addTrailingSlash(storyUrl)}${query}${hash}`)
    : url
    ? addPrecedingSlash(
        `${url.trim()}${existingSearch ? query.replace('?', '&') : query}`
      )
    : ''

  const hasIcon = !!icon && !!icon.length
  const hasIconSvg = !!iconSvg && !!iconSvg.length
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.

  const [href, setHref] = useState(initHref)

  useEffect(() => {
    setHref(initHref)
  }, [initHref])
  //
  // Use Gatsby Link for internal links, and <a> for others

  const hyperlinkedInner = () => (
    <>
      {hasIcon && <Box className={classes.icon}>{renderBloks(icon)}</Box>}
      {hasIconSvg && <Icon styles={classes.iconSvg}>{iconSvg}</Icon>}

      {toHyperlink}
      {!!subLink && subLink.length !== 0 && renderBloks(subLink)}
    </>
  )

  const rel = openInNewTab ? 'noreferrer' : noFollow ? 'true' : 'external'
  const linkTarget = openInNewTab ? '_blank' : target || null
  const clickHandler =
    !!onClick && typeof onClick === 'function'
      ? (e) => {
          e.preventDefault()
          onClick()
        }
      : null

  const toggleMutiny = (MutinyConv) => {
    window.mutiny = window.mutiny || []
    window.mutiny?.client?.trackConversion({ name: MutinyConv })
  }

  return !!modal && modal.length > 0 ? (
    <>
      <span
        href={href}
        className={classNames(
          classes.cursorStyle,
          className || classes[variant || 'standard']
        )}
        style={style}
        onClick={
          (modal && modal.length > 0 && (() => toggleModal(true))) ||
          (mutinyConversion &&
            mutinyConversion.length > 0 &&
            (() => toggleMutiny(mutinyConversion))) ||
          undefined
        }
        {...analytics}
      >
        {hyperlinkedInner()}
      </span>
      {OpenModal && (
        <Modal
          open={OpenModal}
          handleClose={() => toggleModal(false)}
          title={title}
        >
          {!!modal && renderBlok(otherModalProps)}
        </Modal>
      )}
    </>
  ) : isStory && !context.isInEditor ? (
    <GatsbyLink
      to={href}
      activeClassName="active"
      gatsbyLinkProps={{
        ...analytics,
      }}
      className={classNames(
        classes.cursorStyle,
        className || classes[variant || 'standard']
      )}
      style={style}
      partiallyActive={false}
      state={state}
      replace={replace}
      target={linkTarget}
      onAnchorLinkClick={(e) => {
        e.preventDefault()
        if (forcePageLoad) {
          window.location.href = href
        }
      }}
    >
      {hyperlinkedInner()}
    </GatsbyLink>
  ) : (
    <a
      href={href}
      className={classNames(
        classes.cursorStyle,
        className || classes[variant || 'standard']
      )}
      rel={rel}
      target={linkTarget}
      onClick={
        clickHandler ||
        (modal && modal.length > 0 && (() => toggleModal(true))) ||
        (mutinyConversion &&
          mutinyConversion.length > 0 &&
          (() => toggleMutiny(mutinyConversion))) ||
        undefined
      }
      {...other}
      {...analytics}
    >
      {hyperlinkedInner()}
    </a>
  )
}

export default Link
