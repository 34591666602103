const gatsbyLocale = process.env.GATSBY_LOCALE || 'us'
const localizedSiteData = {
  // global folder - /us
  us: {
    locale: 'us',
    primaryBuildFolder: 'us',
    buildFolders: ['us'],
    text: 'United States',
    hreflangs: ['en'],
    url: 'https://construction.autodesk.com',
    deployUrl: 'https://accweb.netlify.app',
  },
  // /gb folder
  gb: {
    locale: 'gb',
    primaryBuildFolder: 'gb',
    buildFolders: ['us', 'gb'],
    text: 'United Kingdom',
    hreflangs: ['en-gb', 'en-cy'],
    url: 'https://construction.autodesk.co.uk',
    deployUrl: 'https://acc-gb.netlify.app',
  },
  eu: {
    locale: 'eu',
    primaryBuildFolder: 'gb',
    buildFolders: ['us', 'gb'],
    text: 'Europe (English)',
    hreflangs: [
      'en-ie',
      'en-dk',
      'en-no',
      'en-se',
      'en-fi',
      'en-is',
      'en-be',
      'en-nl',
      'en-lu',
      'en-es',
    ],
    url: 'https://construction.autodesk.eu',
    deployUrl: 'https://acc-europe.netlify.app',
  },
  // /au folder
  au: {
    locale: 'au',
    primaryBuildFolder: 'au',
    buildFolders: ['us', 'au'],
    text: 'Australia',
    hreflangs: ['en-au'],
    url: 'https://construction.autodesk.com.au',
    deployUrl: 'https://acc-au.netlify.app',
  },
  nz: {
    locale: 'nz',
    primaryBuildFolder: 'au',
    buildFolders: ['us', 'au'],
    text: 'New Zealand',
    hreflangs: ['en-nz'],
    url: 'https://construction.autodesk.co.nz',
    deployUrl: 'https://acc-nz.netlify.app',
  },
  // /jp folder
  jp: {
    locale: 'jp',
    primaryBuildFolder: 'jp',
    buildFolders: ['jp'],
    text: 'Japan',
    hreflangs: ['ja'],
    url: 'https://construction.autodesk.co.jp',
    deployUrl: 'https://acc-jp.netlify.app',
  },
  // /de folder
  de: {
    locale: 'de',
    primaryBuildFolder: 'de',
    buildFolders: ['de'],
    text: 'Germany',
    hreflangs: ['de', 'de-at', 'de-ch'],
    url: 'https://construction.autodesk.de',
    deployUrl: 'https://acc-de.netlify.app',
  },
  // /fr folder
  fr: {
    locale: 'fr',
    primaryBuildFolder: 'fr',
    buildFolders: ['fr'],
    text: 'France',
    hreflangs: ['fr'],
    url: 'https://construction.autodesk.fr',
    deployUrl: 'https://acc-fr.netlify.app',
  },
}

// prettier-ignore
const europeanCountries = ['fi', 'it', 'se', 'fr', 'pl', 'de', 'es', 'gb', 'at', 'be', 'bg', 'hr', 'cy', 'cz', 'dk', 'ee', 'gr', 'hu', 'ie', 'lv', 'lt', 'lu', 'mt']
const userGeoSiteMatchData = {
  gb: 'gb',
  au: 'au',
  nz: 'nz',
  us: 'us',
  de: 'de',
  fr: 'fr',
  jp: 'jp',
  eu: europeanCountries,
}

const currentBuild = localizedSiteData[gatsbyLocale]

const buildFoldersForLocale = currentBuild.buildFolders

const primaryBuildFolder = currentBuild.primaryBuildFolder

const allSiteBuilds = Object.values(localizedSiteData)

const allBuildFolders = Array.from(
  new Set(allSiteBuilds.map((build) => build.primaryBuildFolder))
)

// Images
const imgMaxWidths = {
  headerImage: 1500,
  markdown: 800,
  resourceCard: 350,
}

const tealiumSrc = {
  prod: {
    utag: '//tags.tiqcdn.com/utag/autodesk/acs/prod/utag.js',
    utagSync: '/tags.tiqcdn.com/utag/autodesk/acs/prod/utag.sync.js',
  },
  staging: {
    utag: '//tags.tiqcdn.com/utag/autodesk/acs-qa/qa/utag.js',
    utagSync: '/tags.tiqcdn.com/utag/autodesk/acs-qa/qa/utag.sync.js',
  },
}

export {
  localizedSiteData,
  userGeoSiteMatchData,
  europeanCountries,
  currentBuild,
  buildFoldersForLocale,
  primaryBuildFolder,
  allSiteBuilds,
  allBuildFolders,
  imgMaxWidths,
  tealiumSrc,
  gatsbyLocale,
}
